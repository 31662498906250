<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.2612 16.2584C24.4762 16.2584 23.0226 14.86 23.0226 13.1407C23.0226 11.4214 24.4762 10.023 26.2612 10.023C28.0465 10.023 29.4999 11.4214 29.4999 13.1407C29.4999 14.86 28.0465 16.2584 26.2612 16.2584ZM26.2612 11.2701C25.1903 11.2701 24.318 12.1089 24.318 13.1407C24.318 14.1726 25.1903 15.0114 26.2612 15.0114C27.3322 15.0114 28.2044 14.1726 28.2044 13.1407C28.2044 12.1089 27.3322 11.2701 26.2612 11.2701Z"
      fill="black" stroke="black"/>
    <path
      d="M26.2612 29.977C24.4762 29.977 23.0226 28.5786 23.0226 26.8593C23.0226 25.14 24.4762 23.7415 26.2612 23.7415C28.0465 23.7415 29.4999 25.14 29.4999 26.8593C29.4999 28.5786 28.0465 29.977 26.2612 29.977ZM26.2612 24.9886C25.1903 24.9886 24.318 25.8274 24.318 26.8593C24.318 27.8911 25.1903 28.7299 26.2612 28.7299C27.3322 28.7299 28.2044 27.8911 28.2044 26.8593C28.2044 25.8274 27.3322 24.9886 26.2612 24.9886Z"
      fill="black" stroke="black"/>
    <path
      d="M13.7388 23.1179C11.9536 23.1179 10.5001 21.7195 10.5001 20.0002C10.5001 18.2809 11.9536 16.8825 13.7388 16.8825C15.524 16.8825 16.9774 18.2809 16.9774 20.0002C16.9774 21.7195 15.524 23.1179 13.7388 23.1179ZM13.7388 18.1296C12.6679 18.1296 11.7956 18.9684 11.7956 20.0002C11.7956 21.032 12.6679 21.8708 13.7388 21.8708C14.8097 21.8708 15.682 21.032 15.682 20.0002C15.682 18.9684 14.8097 18.1296 13.7388 18.1296Z"
      fill="black" stroke="black"/>
    <path
      d="M15.9941 19.3925C15.7686 19.3925 15.5493 19.2793 15.431 19.0782C15.2539 18.779 15.3619 18.3981 15.6728 18.2277L23.6866 13.8296C23.9965 13.6576 24.393 13.7623 24.5691 14.0625C24.7462 14.3618 24.6382 14.7425 24.3273 14.913L16.3136 19.311C16.2125 19.3658 16.1029 19.3925 15.9941 19.3925V19.3925Z"
      fill="black" stroke="black"/>
    <path
      d="M24.0071 26.2519C23.8983 26.2519 23.7885 26.2253 23.6875 26.1697L15.6737 21.7716C15.3628 21.6013 15.2549 21.2204 15.4319 20.9211C15.6081 20.621 16.0037 20.5171 16.3146 20.6883L24.3283 25.0863C24.6391 25.2568 24.7472 25.6375 24.5702 25.9368C24.451 26.1388 24.2316 26.2519 24.0071 26.2519Z"
      fill="black" stroke="black"/>
    <circle cx="20" cy="20" r="19.5" stroke="black"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconShareQrPopup = Vue.extend({
  name: 'icon-share-qr-popup',
});
export default IconShareQrPopup;
</script>
