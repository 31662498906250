


import Component, { mixins } from 'vue-class-component';
import {Action, Getter} from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import EwAvatar from '@/_modules/standalone-company/components/UI/ew-avatar/ew-avatar.vue';
import NotificationsMixin from '@/_mixins/notifications.mixin.ts';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { TMessage } from '@/_types/messages.type';
import { TLastContact } from '@/_modules/promo/types/side-bar-right-store-state.type.ts';
import { TUser } from '@/_types/user.type';

@Component({
  components: {
    EwAvatar,
  },
})
export default class LastContacts extends mixins(NotificationsMixin) {
  @Getter('_userStore/user') readonly userInfo: TUser;
  @Getter('messagesStore/messages') readonly messages: TApiListResponse<TMessage>;
  @Getter('sideBarRightStore/lastContactsCollection') lastContactsCollection: TLastContact[];
  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number; startupTabName: string }) => void;

  @Watch('messages.List')
  onMessagesChanged(): void {
    if (this.messages && this.messages.List.length) {
      this.setLastContacts();
    }
  }

  public created(): void {
    this.getUserCorrespondence();
  }

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get lastContacts(): TLastContact[] {
    return this.lastContactsCollection;
  }

  public getLastContactTooltipContent(lastContact: TLastContact): string {
    const contact = lastContact.contact;
    if (!contact) {
      return '';
    }
    const fullName: string = [contact.name, contact.surname].filter(x => x).join(' ');
    return fullName || this.$t('contacts.info.noname') as string;
  }

  private async getUserCorrespondence(): Promise<void> {
    if (!this.userInfo) {
      setTimeout(() => {
        this.getUserCorrespondence();
      }, 1000);
      return;
    }

    await this.$store.dispatch('messagesStore/callMessageList', {
      eventId: this.eventId,
      userId: this.userInfo.id,
      limit: 1000
    });
  }

  private setLastContacts(): void {
    if (!this.messages) { return; }
    this.messages.List.forEach(item => {
      this.$store.dispatch('sideBarRightStore/addLastContact', item.contact);
    });
  }

  public onLastContactClick(lastContact: TLastContact): void {
    if (!lastContact.contact || !lastContact.contact.id) {
      return;
    }
    this.openContactCard({
      contactId: lastContact.contact.id,
      startupTabName: 'messages',
    });
  }

}
