<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.2687 2.66667C18.2687 1.19644 17.0633 0 15.5821 0H2.68657C1.20537 0 0 1.19644 0 2.66667V16.8889C0 17.0956 0.121343 17.2853 0.309403 17.372C0.380597 17.4049 0.459403 17.4222 0.537313 17.4222C0.662687 17.4222 0.784925 17.3782 0.881194 17.2987L5.03015 13.8667H15.5821C17.0633 13.8667 18.2687 12.6702 18.2687 11.2V2.66667ZM17.194 11.2C17.194 12.0822 16.4709 12.8 15.5821 12.8H4.83582C4.71045 12.8 4.58866 12.844 4.49194 12.924L1.07463 15.7511V2.66667C1.07463 1.78444 1.79776 1.06667 2.68657 1.06667H15.5821C16.4709 1.06667 17.194 1.78444 17.194 2.66667V11.2Z"
      fill="black"/>
    <path
      d="M21.313 6.57778H17.6798C17.6798 6.59422 17.6794 6.61067 17.6794 6.62711C17.6749 6.96622 17.666 7.30533 17.6548 7.64444H21.313C22.2018 7.64444 22.9249 8.36222 22.9249 9.24444V22.3289L19.5076 19.5018C19.4109 19.4218 19.2891 19.3778 19.1637 19.3778H8.41746C7.52866 19.3778 6.80552 18.66 6.80552 17.7778V13.5374L5.7309 13.3701V17.7778C5.7309 19.248 6.93627 20.4444 8.41746 20.4444H18.9694L23.1188 23.8764C23.2151 23.956 23.3369 24 23.4627 24C23.5406 24 23.6194 23.9827 23.6906 23.9498C23.8782 23.8631 24 23.6733 24 23.4667V9.24444C23.9996 7.77422 22.7942 6.57778 21.313 6.57778Z"
      fill="black"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconCorrespondence = Vue.extend({
  name: 'icon-correspondence',
});
export default IconCorrespondence;
</script>
