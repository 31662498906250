


import {Component, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {TNotification} from '@/_modules/promo/types/notification.type';
import PromoNotification from '@/_modules/promo/components/promo-notification/promo-notification.vue';
import {NotificationType} from '@/_modules/promo/types/notification-type.enum';
import {TEventSettings} from '@/_types/event-settings.type';

@Component({
  components: {
    PromoNotification,
  },
})
export default class SideBarRightTabNotifications extends Vue {

  @Getter('notificationsStore/notifications') notifications: TNotification[];
  @Getter('_eventStore/isEventSettingsPolled') isEventSettingsPolled: boolean;
  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public created(): void {
    this.$store.dispatch('_eventStore/getEventSettings', { eventId: this.eventId });
  }

  public get isDirectMessagingDisabled(): boolean {
    if (!this.isEventSettingsPolled) {
      return true;
    }

    if (!this.eventSettings || !this.eventSettings.layout) {
      return false;
    }

    return this.eventSettings.layout.is_direct_messaging_disabled === true;
  }

  public get notificationsFiltered(): TNotification[] {
    if (!this.isDirectMessagingDisabled) {
      return this.notifications;
    }

    return this.notifications.filter(notification => {
      return notification.type !== NotificationType.CONTACT_MESSAGE;
    });
  }
}
