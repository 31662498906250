











import Component from 'vue-class-component';
import { Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class IconHelp extends Vue {
  @Prop({type: String, default: '#000000'}) public readonly fillColor: string;
}
