<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9907 10.3182H11.7321L15.7114 14.314C16.0962 14.6986 16.0962 15.327 15.7114 15.7116C15.3266 16.0961 14.6978 16.0961 14.313 15.7116L10.3243 11.7252V10.9842L10.0709 10.7216C8.75697 11.8471 6.9644 12.4287 5.05921 12.1098C2.45012 11.6689 0.36661 9.49284 0.0475131 6.86652C-0.440517 2.89891 2.90061 -0.440259 6.87055 0.0474853C9.49841 0.366395 11.6758 2.44869 12.1169 5.05624C12.436 6.96032 11.8541 8.75184 10.7279 10.065L10.9907 10.3182ZM1.8776 6.09738C1.8776 8.43293 3.76403 10.3182 6.10094 10.3182C8.43786 10.3182 10.3243 8.43293 10.3243 6.09738C10.3243 3.76184 8.43786 1.87652 6.10094 1.87652C3.76403 1.87652 1.8776 3.76184 1.8776 6.09738Z" fill="#001B24" fill-opacity="0.4"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconEWInputSearch = Vue.extend({
  name: 'icon-ew-input-search',
});
export default IconEWInputSearch;
</script>
