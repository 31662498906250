var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.sideBarLeftKeyForRefreshOnNarrowScreens,staticClass:"side-bar-left",class:{
    'not-ready': _vm.isEventSettingsPolled !== true
  },on:{"mouseenter":function($event){return _vm.hoverHandler($event)},"mouseleave":function($event){return _vm.hoverHandler($event)}}},[_c('div',{staticClass:"sandwich"},[_vm._v("≡")]),_vm._l((_vm.activeMenuItems),function(item,index){return _c('div',{key:'sideBarLeftMenuItem_' + index,staticClass:"cur-p menu-item",class:{
      'menu-item-active': item.isActive(),
      'menu-item-disabled': item.isDisabled() || !_vm.canView(_vm.routeNamesToSettingsKeys[item.routeName]),
      'divider-before': _vm.isMenuItemHavingDividerBefore(item),
      'custom-action-name-help': item.customActionName === 'help'
    }},[_c('a',{staticClass:"link",attrs:{"href":_vm.getMenuItemHref(item)},on:{"click":function($event){return _vm.menuItemClickHandler($event, item)}}},[_c(item.iconComponentName,{tag:"component",staticClass:"icon"}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.getMenuItemTitle(item)))]),(_vm.isCalendarBadgeNotificationNeeded(item))?_c('span',{staticClass:"badge-notification"},[_vm._v(" "+_vm._s(_vm.prepareBadgeNotificationNumber(_vm.meetingsCount - _vm.noticedMeetingsCount))+" ")]):_vm._e()],1)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }