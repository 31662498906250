


import Component, { mixins } from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { TContact } from '@/_types/contact.type';
import { TUser } from '@/_types/user.type';
import { TEvent } from '@/_types/event.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TabNames } from '@/_modules/promo/types/side-bar-right-store-state.type.ts';
import Avatar from '@/_components/avatar/avatar.vue';
import sideBarRightTabContact
  from '@/_modules/promo/components/side-bar-right-tab-contact/side-bar-right-tab-contact.vue';
import sideBarRightTabCorrespondence
  from '@/_modules/promo/components/side-bar-right-tab-correspondence/side-bar-right-tab-correspondence.vue';
import EwClosePopUpIcon from '@/_modules/icons/components/ew-close-pop-up-icon.vue';
import IconCorrespondence from '@/_modules/icons/components/sidebar/icon-correspondence.vue';
import IconSchedule from '@/_modules/icons/components/sidebar/icon-schedule.vue';
import IconNotifications from '@/_modules/icons/components/sidebar/icon-notifications.vue';
import SideBarRightTabNotifications
  from '@/_modules/promo/components/side-bar-right-tab-notifications/side-bar-right-tab-notifications.vue';
import SideBarRightTabSchedule
  from '@/_modules/promo/components/side-bar-right-tab-schedule/side-bar-right-tab-schedule.vue';
import NotificationsMixin from '@/_mixins/notifications.mixin.ts';
import LastContacts from '@/_modules/promo/components/side-bar-right/last-contacts/last-contacts.vue';
import EwAvatar from '@/_modules/standalone-company/components/UI/ew-avatar/ew-avatar.vue';
import { TEventRestrictions } from '@/_modules/events/api/event/event.api';
import { TContactPermissions } from '@/_modules/contacts/store/contacts.store';
import {TEventSettings} from '@/_types/event-settings.type';

@Component({
  components: {
    Avatar,
    EwAvatar,
    sideBarRightTabContact,
    sideBarRightTabCorrespondence,
    EwClosePopUpIcon,
    IconCorrespondence,
    IconSchedule,
    IconNotifications,
    SideBarRightTabNotifications,
    SideBarRightTabSchedule,
    LastContacts
  },
})
export default class PromoSideBarRight extends mixins(NotificationsMixin) {

  @Getter('_userStore/user') readonly userInfo: TUser;
  @Getter('_eventStore/event') readonly event: TEvent;
  @Getter('_eventStore/eventSettings') readonly eventSettings: TEventSettings;
  @Getter('_eventStore/isEventSettingsPolled') isEventSettingsPolled: boolean;
  @Getter('promoPageStore/promoPage') readonly promoPage: TPromoPage;
  @Getter('promoPageStore/contact') readonly contactInfo: TContact;
  @Getter('authStore/isAuthenticated') readonly isAuthenticated: boolean;
  @Getter('notificationsStore/messagesCount') readonly messagesCount: number;
  @Getter('notificationsStore/meetingsCount') readonly meetingsCount: number;
  @Getter('notificationsStore/noticedMeetingsCount') readonly noticedMeetingsCount: number;
  @Getter('notificationsStore/noticedMessagesCount') readonly noticedMessagesCount: number;
  @Getter('sideBarRightStore/isOpen') readonly isOpen: boolean;
  @Getter('sideBarRightStore/activeTabName') readonly activeTabName: TabNames;
  @Getter('sideBarRightStore/isUserSettingsOpen') readonly isUserSettingsOpen: boolean;
  @Getter('_eventStore/eventRestrictions') eventRestrictions: TEventRestrictions;
  @Getter('contactsStore/contactPermissions') contactPermissions: TContactPermissions;

  @Action('sideBarRightStore/setComponentPresence') setComponentPresence: (isPresent: boolean) => void;
  @Action('sideBarRightStore/open') openTheSideBar: () => void;
  @Action('sideBarRightStore/close') closeTheSideBar: () => void;
  @Action('sideBarRightStore/toggle') toggleTheSideBar: () => void;
  @Action('sideBarRightStore/clearActiveTab') clearActiveTab: () => void;
  @Action('sideBarRightStore/setIsUserSettingsOpen') setIsUserSettingsOpen: (isOpen: boolean) => void;
  @Action('sideBarRightStore/setActiveTab') setActiveTab: (activeTabName: TabNames) => void;

  public TabNames: typeof TabNames = TabNames;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get contactInitials(): string {
    if (!this.contactInfo || !this.contactInfo.name || !this.contactInfo.surname) {
      return '';
    }
    return (this.contactInfo.name.substring(0, 1) + this.contactInfo.surname.substring(0, 1)).toUpperCase();
  }

  public get eventSettingsLayout(): any {
    return (this.event && this.eventSettings && this.eventSettings.layout) || null;
  }

  public get isEventChatEnabled(): boolean {
    return (this.eventSettingsLayout && this.eventSettingsLayout.is_event_chat_enabled) || false;
  }

  public get isDirectMessagingEnabled(): boolean {
    if (!this.isEventSettingsPolled) {
      return false;
    }
    if (!this.eventSettingsLayout) {
      return true;
    }
    return this.eventSettingsLayout.is_direct_messaging_disabled !== true;
  }

  public created(): void {
    this.setComponentPresence(true);
  }

  public mounted(): void {
    this.setComponentPresence(true);
  }

  public beforeDestroy(): void {
    this.setComponentPresence(false);
  }

  public foldTheSideBar(): void {
    this.closeTheSideBar();
    this.clearActiveTab();
  }

  public onMenuItemClick(tabName: TabNames): void {
    if (!this.isOpen) {
      this.openTheSideBar();
    } else if ((tabName === TabNames.CONTACT) && this.isUserSettingsOpen) {
      this.setIsUserSettingsOpen(false);
    } else if (tabName === this.activeTabName) {
      this.toggleTheSideBar();
      tabName = TabNames.NONE;
    }
    this.setActiveTab(tabName);
  }

  public isPermissionRequired(thingName: string): boolean {
    const restrictionsData = (this.eventRestrictions && this.eventRestrictions.restrictions_data) || [];
    return restrictionsData.length && restrictionsData.indexOf(thingName) >= 0;
  }

  public hasPermission(thingName: string): boolean {
    const permissionsData = (this.contactPermissions && this.contactPermissions.permissions_data) || [];
    return permissionsData.length && permissionsData.indexOf(thingName) >= 0;
  }

  public canView(thingName: string): boolean {
    return !this.isPermissionRequired(thingName) || !this.hasPermission(thingName);
  }

}
