<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="19.5" stroke="black"/>
    <path
      d="M19.2712 24.9239C19.3669 25.0161 19.4806 25.0892 19.6057 25.1391C19.7309 25.189 19.865 25.2147 20.0004 25.2147C20.1358 25.2147 20.2699 25.189 20.3951 25.1391C20.5202 25.0892 20.6339 25.0161 20.7296 24.9239L24.6187 21.1801C24.8121 20.9939 24.9207 20.7414 24.9207 20.4781C24.9207 20.2148 24.8121 19.9623 24.6187 19.7761C24.4253 19.5899 24.163 19.4854 23.8895 19.4854C23.616 19.4854 23.3537 19.5899 23.1603 19.7761L21.0317 21.8252V10.9854C21.0317 10.7221 20.923 10.4696 20.7296 10.2834C20.5362 10.0973 20.2739 9.99268 20.0004 9.99268C19.7269 9.99268 19.4646 10.0973 19.2712 10.2834C19.0778 10.4696 18.9692 10.7221 18.9692 10.9854V21.8252L16.8405 19.7761C16.6471 19.5899 16.3848 19.4854 16.1113 19.4854C15.8378 19.4854 15.5755 19.5899 15.3821 19.7761C15.1887 19.9623 15.0801 20.2148 15.0801 20.4781C15.0801 20.7414 15.1887 20.9939 15.3821 21.1801L19.2712 24.9239Z"
      fill="black"/>
    <path
      d="M28.9375 18.5964C28.664 18.5964 28.4017 18.701 28.2083 18.8871C28.0149 19.0733 27.9062 19.3258 27.9062 19.5891V27.2001H12.0938V19.5891C12.0938 19.3258 11.9851 19.0733 11.7917 18.8871C11.5983 18.701 11.336 18.5964 11.0625 18.5964C10.789 18.5964 10.5267 18.701 10.3333 18.8871C10.1399 19.0733 10.0312 19.3258 10.0312 19.5891V27.531C10.0312 27.9698 10.2123 28.3907 10.5347 28.701C10.857 29.0113 11.2942 29.1856 11.75 29.1856H28.25C28.7058 29.1856 29.143 29.0113 29.4653 28.701C29.7877 28.3907 29.9688 27.9698 29.9688 27.531V19.5891C29.9688 19.3258 29.8601 19.0733 29.6667 18.8871C29.4733 18.701 29.211 18.5964 28.9375 18.5964Z"
      fill="black"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconDownloadQrPopup = Vue.extend({
  name: 'icon-download-qr-popup',
});
export default IconDownloadQrPopup;
</script>
