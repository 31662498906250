


import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import PromoSideBarLeft from '@/_modules/promo/components/side-bar-left/side-bar-left.vue';
import PromoSideBarRight from '@/_modules/promo/components/side-bar-right/side-bar-right.vue';
import SearchBar from '@/_components/search-bar/search-bar.vue';
import PageTitle from '@/_components/page-title/page-title.vue';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import {TTicket} from '@/_api/tickets.api';

@Component({
  components: {
    EwButton,
    PromoSideBarLeft,
    PromoSideBarRight,
    SearchBar,
    PageTitle,
  }
})
export default class TicketsViewer extends Vue {

  @Getter('ticketsStore/isLoading') isTicketsStoreLoading: boolean;
  @Getter('_eventStore/isLoading') isEventLoading: boolean;
  @Getter('ticketsStore/tickets') tickets: TTicket[];

  public get ticketsPageTitleHTML(): string {
    if (this.$route.name === 'event-tickets') {
      return this.$t('title.ticketsList') as string;
    }

    return [
      '<a class="tickets-page-breadcrumb-link" href="' + this.$router.resolve({name: 'event-tickets'}).href + '">' + this.$t('title.ticketsList') + '</a>',
      this.$route.name === 'view-ticket' ? '<span>' + this.$t('title.viewTicketPage') + '</span>' : null,
      this.$route.name === 'create-ticket' ? '<span>' + this.$t('title.addTicketPage') + '</span>' : null,
    ]
      .filter(x => x)
      .join('<span class="tickets-page-breadcrumb-separator"></span>');
  }

  public onCreateTicketClick(): void {
    this.$router.push({name: 'create-ticket'}).catch(() => { /* ignore */ });
  }

  public get hasTickets(): boolean {
    return !!(this.tickets && this.tickets.length > 0);
  }

  public get isBackgroundImageVisible(): boolean {
    return this.hasTickets || this.$route.name === 'create-ticket';
  }

}
