var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ew-search-input",class:{
    'ew-input-disabled': _vm.disabled,
    'ew-input-focused': _vm.isFocused,
    'ew-input-has-label': _vm.labelText !== '',
    'ew-input-has-error': _vm.errorText !== '',
    'ew-input-full-width': _vm.fullWidth,
  }},[(_vm.labelText)?_c('label',{on:{"click":_vm.onLabelClick}},[_vm._v(" "+_vm._s(_vm.labelText)+" ")]):_vm._e(),_c('div',{staticClass:"ew-input-field-wrap",on:{"click":_vm.onLabelClick}},[_c('span',{staticClass:"icon"},[_c('IconEWInputSearch')],1),_c('input',{ref:"input",staticClass:"ew-input",class:{
        'ew-input-disabled': _vm.disabled
      },attrs:{"type":"text","disabled":_vm.disabled,"placeholder":_vm.placeholderText,"minlength":_vm.minlength,"maxlength":_vm.maxlength},domProps:{"value":_vm.value},on:{"focus":function($event){return _vm.onInputFocus()},"blur":function($event){return _vm.onInputBlur()},"input":function($event){return _vm.$emit('input', $event.target.value)},"keyup":function($event){return _vm.$emit('keyup', $event.keyup)}}})]),(_vm.isBottomTextEmpty)?_c('div',{staticClass:"ew-input-bottom-text ew-input-bottom-text-empty"}):_vm._e(),(_vm.helpText !== '' && _vm.errorText === '')?_c('div',{staticClass:"ew-input-bottom-text ew-input-help-text"},[_vm._v(" "+_vm._s(_vm.helpText)+" ")]):_vm._e(),(_vm.errorText !== '')?_c('div',{staticClass:"ew-input-bottom-text ew-input-error-wrap"},[(_vm.showErrorIcon && !(_vm.errorData && Object.keys(_vm.errorData).length > 0))?_c('icon-ew-input-error',{staticClass:"icon"}):_c('ErrorInfo',{attrs:{"error-data":_vm.errorData}}),_vm._v(" "+_vm._s(_vm.errorText)+" ")],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }