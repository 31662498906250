


import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import { TabNames } from '@/_modules/promo/types/side-bar-right-store-state.type';
import IconCloseQrPopup from '@/_modules/icons/components/qrpopup/icon-close-qr-popup.vue';
import IconDownloadQrPopup from '@/_modules/icons/components/qrpopup/icon-download-qr-popup.vue';
import IconShareQrPopup from '@/_modules/icons/components/qrpopup/icon-share-qr-popup.vue';
// @ts-ignore
import QrcodeVue from 'qrcode.vue';
import {TOpenEwSharerPayload} from '@/_store/ew-sharer.store';

@Component({
  components: {
    QrcodeVue,
    IconCloseQrPopup,
    IconDownloadQrPopup,
    IconShareQrPopup,
  },
})
export default class QrPopup extends Vue {

  @Getter('sideBarRightStore/isOpen') isSideBarRightOpen: boolean;
  @Getter('sideBarRightStore/activeTabName') activeTabName: TabNames;

  @Getter('ewSharerStore/isSharerVisible') isSharerVisible: boolean;

  @Action('ewSharerStore/openSharer') openSharer: (payload: TOpenEwSharerPayload) => void;
  @Action('ewSharerStore/closeSharer') closeSharer: () => void;

  @Ref('qrCanvasWrap') qrCanvasWrap: Vue;

  public openingAnimationInitialized: boolean = false;
  public openingAnimTimeoutId: number = -1;

  @Prop({type: String, default: ''})
  public readonly stringToEncode: string;

  @Prop({type: String, default: ''})
  public readonly popupTitle: string;

  @Prop({type: String, default: ''})
  public readonly popupDescription: string;

  public mounted(): void {
    this.initOpeningAnimation();
  }

  public beforeDestroy(): void {
    this.killOpeningAnimation();
  }

  private emitClose(): void {
    this.$emit('closeQRPopup');
  }

  private initOpeningAnimation(): void {
    this.openingAnimTimeoutId = window.setTimeout(() => {
      this.openingAnimationInitialized = true;
    }, 150);
  }

  private killOpeningAnimation(): void {
    if (this.openingAnimTimeoutId > -1) {
      window.clearTimeout(this.openingAnimTimeoutId);
    }
  }

  private toggleSharer(event: PointerEvent): void {
    if (this.isSharerVisible) {
      this.closeSharer();
      return;
    }

    this.openSharer({
      eventTarget: event.target as Element,
      url: this.stringToEncode,
    });
  }

  /* Trigger downloading of a PNG image from canvas to a file.
   * Filename is created from strToEncode, PNG is default in Canvas.toDataURL
   * Creates a temporary HTMLAnchorElement in <body>, clicks it and removes it
   */
  private downloadQrAsFile(): void {
    if (!this.qrCanvasWrap) {
      return;
    }
    const qrCanvas: HTMLCanvasElement = (this.qrCanvasWrap as Vue).$el.getElementsByTagName('canvas')[0];
    if (!qrCanvas) {
      return;
    }
    const filename: string = this.stringToEncode.replace('https://', '').replace(/[/.]+/g, '_');
    const tempAnchor: HTMLAnchorElement = document.createElement('a');
    tempAnchor.href = `${process.env.VUE_APP_API_BASE_URL}/qr?text=${this.stringToEncode}`;
    tempAnchor.setAttribute('download', filename + '.png');
    tempAnchor.setAttribute('style', 'position: absolute; top: -100px; left: -100px; z-index: 2;');
    tempAnchor.innerHTML = 'qr code to download';
    document.getElementsByTagName('body')[0].appendChild(tempAnchor);
    tempAnchor.click();
    this.$nextTick(() => {
      tempAnchor.parentNode.removeChild(tempAnchor);
    });
  }
}
