<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 2.5H21C22.3807 2.5 23.5 3.61929 23.5 5V21C23.5 22.3807 22.3807 23.5 21 23.5H3C1.61929 23.5 0.5 22.3807 0.5 21V5C0.5 3.61929 1.61929 2.5 3 2.5Z"
      stroke="black"/>
    <path d="M1 7H23" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="6" width="1" height="5" rx="0.5" fill="black"/>
    <rect x="12" width="1" height="5" rx="0.5" fill="black"/>
    <rect x="18" width="1" height="5" rx="0.5" fill="black"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconSchedule = Vue.extend({
  name: 'icon-schedule',
});
export default IconSchedule;
</script>
