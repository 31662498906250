


import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { RawLocation } from 'vue-router';
import { TranslateResult } from 'vue-i18n';
import { TEvent } from '@/_types/event.type';
import EwCalendar from '@/_modules/ew-calendar/ew-calendar.vue';
import { ScheduleItemType, TDateSchedule, TMeeting, TScheduleItem } from '@/_types/meeting/meeting.type';
import { TConferenceRoom } from '@/_modules/promo/types/conference-room.type';
import { TUser } from '@/_types/user.type';
import { TContact } from '@/_types/contact.type';
import { DateTimeFormat } from '@/_types/date-time-format.enum';
import { MeetingStatus } from '@/_modules/meeting-rooms/types/meeting-status.enum';
import Avatar from '@/_components/avatar/avatar.vue';
import AddToCalendar from '@/_components/add-to-calendar/add-to-calendar.vue';
import MeetingsHelper from '@/_helpers/meetings.helper';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import { TScheduleDay } from '@/_modules/ew-calendar/types/ew-calendar.type';
import Person from '@/_modules/contacts/components/person/person.vue';
import IconVideoCall from '@/_modules/icons/components/sidebar/icon-video-call.vue';

@Component({
  components: {
    Avatar,
    AddToCalendar,
    EwCalendar,
    Person,
    IconVideoCall
  },
})
export default class SideBarRightTabSchedule extends Vue {

  public readonly ScheduleItemType: typeof ScheduleItemType = ScheduleItemType;

  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number }) => void;

  @Getter('_userStore/user') user: TUser;
  @Getter('_eventStore/event') event: TEvent;
  @Getter('promoPageStore/contact') contact: TContact;
  @Getter('promoProgramStore/conferenceRooms') conferenceRooms: TConferenceRoom[];
  @Getter('meetingsStore/getMeetingsByUserId') getMeetingsByUserId: (userId: number) => TMeeting[];
  @Getter('meetingsStore/getIsLoadingByUserId') getIsMeetingsLoadingByUserId: (userId: number) => boolean;
  @Getter('_userStore/isLoading') public readonly isUserLoading: boolean;
  @Getter('_eventStore/isLoading') public readonly isEventLoading: boolean;
  @Getter('promoProgramStore/isLoading') public readonly isProgramLoading: boolean;
  @Getter('promoPageStore/isContactLoading') public readonly isContactLoading: boolean;

  public nowTimestamp: number = (new Date()).getTime();
  public nowTimestampUpdaterIntervalId: number = null;

  public currentDayDate: Date = new Date();
  public scheduleForCurrentDay: TDateSchedule = {
    month: 0,
    monthName: '',
    week: 0,
    date: this.$moment(new Date()),
    dayNumber: 0,
    dateKey: '',
    items: [],
  };

  public mounted(): void {
    this.startNowTimestampUpdate();
  }

  public beforeDestroy(): void {
    this.stopNowTimestampUpdate();
  }

  public get userId(): number {
    return (this.user && this.user.id) || null;
  }

  public get eventId(): number {
    return (this.event && this.event.id) || null;
  }

  public get contactId(): number {
    return (this.contact && this.contact.id) || null;
  }

  public get formattedDate(): string {
    return this.$moment(this.currentDayDate).format('dddd, DD MMMM');
  }

  public get isMeetingsLoading(): boolean {
    if (!this.userId) {
      return false;
    }
    return this.getIsMeetingsLoadingByUserId(this.userId);
  }

  public get confirmedMeetingsForCurrentDay(): TMeeting[] {
    if (!this.userId) {
      return [];
    }
    return this.getMeetingsByUserId(this.userId).filter(meeting => {
      const dateStart = new Date(meeting.date_start); // N.B. creating new Date because we don't want to mutate meeting.date_start
      dateStart.setHours(0);
      dateStart.setMinutes(0);

      return (meeting.status === MeetingStatus.Confirmed)
        && (meeting.creator_contact.id === this.contactId || meeting.user_contact.id === this.contactId)
        && !(meeting.creator_contact.id === this.contactId && meeting.user_contact.id === this.contactId)
        && (+dateStart === +this.currentDayDate);
    });
  }

  public get isLoading(): boolean {
    return this.isProgramLoading
      || this.isEventLoading
      || this.isContactLoading
      || this.isUserLoading
      || this.isMeetingsLoading;
  }

  public get isEmptyCurrentSchedule(): boolean {
    return !this.currentItems
      || !this.currentItems.length;
  }

  public get currentItems(): TScheduleItem[] {
    return (
      this.scheduleForCurrentDay
      && this.scheduleForCurrentDay.items
    ) || [];
  }

  public get scheduleItemsPastTimeMark(): number {
    // Current time - 30 minutes, as specified in AW-1779
    // See isTimeSlotPast for comparison
    return this.nowTimestamp - (1000 * 60 * 30);
  }

  public set scheduleItemsPastTimeMark(value: number) {
    this.nowTimestamp = value;
  }

  public getAddToCalendarTitle(item: TScheduleItem): TranslateResult {
    switch (item.type) {
      case ScheduleItemType.MEETING: {
        const contactId = this.contactId;
        if (item.meeting.creator_contact.id !== contactId) {
          return this.$t('[\'add-to-calendar\'].meeting.title', {
            name: (item.meeting.creator_contact && item.meeting.creator_contact.fullName) || 'noname',
          });
        } else {
          return this.$t('[\'add-to-calendar\'].meeting.title', {
            name: (item.meeting.user_contact && item.meeting.user_contact.fullName) || 'noname',
          });
        }
      }

      case ScheduleItemType.PROGRAM:
        return item.program.title;
    }

    return null;
  }

  public getAddToCalendarDetails(item: TScheduleItem): TranslateResult {
    switch (item.type) {
      case ScheduleItemType.MEETING:
        return this.$t('[\'add-to-calendar\'].meeting.details', {
          link: this.getMeetingShareUrl(item.meeting),
        });

      case ScheduleItemType.PROGRAM:
        return this.$t('[\'add-to-calendar\'].program.details', {
          link: location.origin + this.$router.resolve({
            name: 'promo-program-date-program',
            params: {
              date: this.$moment(item.program.date_start).format(DateTimeFormat.DATE_TINY),
              programId: '' + item.program.id,
            }
          }).href,
        });
    }

    return null;
  }

  public getAddToCalendarLocation(item: TScheduleItem): TranslateResult {
    switch (item.type) {
      case ScheduleItemType.MEETING:
        return this.$t('[\'add-to-calendar\'].meeting.location');

      case ScheduleItemType.PROGRAM:
        return this.$t('[\'add-to-calendar\'].program.location');
    }

    return null;
  }

  public onScheduleItemClick(scheduleItem: TScheduleItem): void {
    if (scheduleItem.type === ScheduleItemType.MEETING) {
      this.openContactCard({
        contactId: scheduleItem.meeting.contact.id,
      });
      return;
    }
    try {
      this.$router.push(
        this.getScheduleItemLocation(scheduleItem)
      ).catch(/* ignore */);
    } catch {
      /* ignore */
    }

  }

  public getScheduleItemLocation(scheduleItem: TScheduleItem): RawLocation {
    switch (scheduleItem.type) {
      case ScheduleItemType.MEETING: {
        const contactId = this.contactId;
        if (scheduleItem.meeting) {
          if (scheduleItem.meeting.user_contact.id !== contactId) {
            return {
              name: 'promo-page-contacts-contact',
              params: { contact_id: '' + scheduleItem.meeting.user_contact.id }
            };
          } else {
            return {
              name: 'promo-page-contacts-contact',
              params: { contact_id: '' + scheduleItem.meeting.creator_contact.id }
            };
          }
        }
        break;
      }

      case ScheduleItemType.PROGRAM: {
        return {
          name: 'promo-program-date-program',
          params: {
            date: this.$moment(scheduleItem.program.date_start).format(DateTimeFormat.DATE_TINY),
            programId: '' + scheduleItem.program.id,
          }
        };
      }
    }

    return '';
  }

  public getScheduleItemContact(scheduleItem: TScheduleItem): TContact {
    if (scheduleItem.type === ScheduleItemType.MEETING) {
      return scheduleItem.meeting.contact;
    }
    return null;
  }

  public getScheduleItemTitle(scheduleItem: TScheduleItem): string {
    switch (scheduleItem.type) {
      case ScheduleItemType.MEETING: {
        const contactId = this.contactId;
        if (scheduleItem.meeting) {
          if (scheduleItem.meeting.user_contact.id !== contactId) {
            return scheduleItem.meeting.user_contact.fullName;
          } else {
            return scheduleItem.meeting.creator_contact.fullName;
          }
        }
        break;
      }

      case ScheduleItemType.PROGRAM: {
        return scheduleItem.program.title;
      }
    }

    return null;
  }

  public getScheduleItemDescription(scheduleItem: TScheduleItem): string {
    switch (scheduleItem.type) {
      case ScheduleItemType.MEETING: {
        const contactId = this.contactId;
        if (scheduleItem.meeting) {
          if (scheduleItem.meeting.user_contact.id !== contactId) {
            return scheduleItem.meeting.user_contact.fullCompany;
          } else {
            return scheduleItem.meeting.creator_contact.fullCompany;
          }
        }
        break;
      }

      case ScheduleItemType.PROGRAM: {
        return scheduleItem.program.description;
      }
    }

    return null;
  }

  public onStartCallClick(item: TScheduleItem): void {
    if (item.type !== ScheduleItemType.MEETING) {
      return;
    }
    const nowTimestamp = (new Date()).getTime();
    if (item.meeting.date_start.getTime() - nowTimestamp > (1000 * 60 * 5)) {
      this.$store.dispatch('meetingsStore/setMeetingTooEarlyPopupVisible', true);
      return;
    }
    const meetingRoomConfig = {
      type: 'meeting',
      eventId: this.eventId,
      meetingId: item.meeting.id,
      contactId: this.contactId,
      meetingDate: this.$moment(item.meeting.date_start).unix(),
    };
    this.$store.dispatch('meetingRoomsStore/join', meetingRoomConfig);
  }

  private getMeetingShareUrl(meeting: TMeeting): string {
    return MeetingsHelper.getMeetingInviteUrl({
      type: MeetingRoomType.MEETING,
      eventId: this.eventId,
      meetingId: meeting.id,
      meetingDate: this.$moment(meeting.date_start).unix(),
    });
  }

  private startNowTimestampUpdate(): void {
    this.nowTimestampUpdaterIntervalId = window.setInterval(() => {
      this.scheduleItemsPastTimeMark = (new Date()).getTime();
    }, 1000 * 60);
  }

  private stopNowTimestampUpdate(): void {
    window.clearInterval(this.nowTimestampUpdaterIntervalId);
  }

  private isScheduleItemCurrent(item: TScheduleItem): boolean {
    if (!item.startTime || !item.endTime) {
      return false;
    }

    const dateStart = item.startTime.toDate();
    const dateEnd = item.endTime.toDate();

    return dateStart.getTime() < this.nowTimestamp
      && dateEnd.getTime() > this.nowTimestamp;
  }

  private isScheduleItemPast(item: TScheduleItem): boolean {
    if (!item.endTime) {
      return false;
    }

    return item.endTime.toDate().getTime() < this.scheduleItemsPastTimeMark;
  }

  public onDateChoiceChange(chosenDay: TScheduleDay): void {
    this.currentDayDate = chosenDay.fullDate;
    this.scheduleForCurrentDay = {
      month: this.$moment(this.currentDayDate).month() + 1,
      monthName: chosenDay.monthName,
      week: this.$moment(chosenDay.weekNumber).week(),
      date: this.$moment(this.currentDayDate),
      dayNumber: +chosenDay.dayNumber,
      dateKey: chosenDay.dayName,
      items: [...this.getAllScheduleItemsForCurrentDate()],
    };
  }

  public getAllScheduleItemsForCurrentDate(): TScheduleItem[] {
    const todayMeetings = this.getMeetingScheduleItemsForCurrentDate();
    const todayProgramSessions = this.getProgramScheduleItemsForCurrentDate();
    return todayMeetings
      .concat(todayProgramSessions)
      .sort(this.sortScheduleItems);
  }

  public getMeetingScheduleItemsForCurrentDate(): TScheduleItem[] {
    const currentDayMeetings: TScheduleItem[] = [];

    this.confirmedMeetingsForCurrentDay.forEach(meeting => {
      const dateStartMoment = this.$moment(meeting.date_start);
      const dateEndMoment = this.$moment(meeting.date_end);

      currentDayMeetings.push({
        type: ScheduleItemType.MEETING,
        startTime: dateStartMoment,
        startTimeKey: dateStartMoment.format(DateTimeFormat.DATE_MEDIUM),
        startTimeText: dateStartMoment.format(DateTimeFormat.HOURS_MINUTES),
        endTime: dateEndMoment,
        endTimeKey: dateEndMoment.format(DateTimeFormat.DATE_MEDIUM),
        endTimeText: dateEndMoment.format(DateTimeFormat.HOURS_MINUTES),
        key: `meeting-${meeting.id}`,
        meeting: meeting,
        markerText: this.$t('promo.schedule.Meeting'),
      });
    });

    return currentDayMeetings;
  }

  public getProgramScheduleItemsForCurrentDate(): TScheduleItem[] {
    if (!this.conferenceRooms) {
      return [];
    }

    const currentDayPrograms: TScheduleItem[] = [];

    this.conferenceRooms.forEach(conferenceRoom => {
      if (!conferenceRoom.programs || !conferenceRoom.programs.length) {
        return;
      }
      conferenceRoom.programs.forEach(program => {
        if (
          this.$moment(program.date_start).isSame(this.currentDayDate, 'day')
          && (
            program.is_favorite
            || (program.speakers && program.speakers.find(speaker => speaker.id === this.contactId))
          )
        ) {
          currentDayPrograms.push({
            type: ScheduleItemType.PROGRAM,
            startTime: this.$moment(program.date_start),
            startTimeKey: this.$moment(program.date_start).format(DateTimeFormat.DATE_MEDIUM),
            startTimeText: this.$moment(program.date_start).format(DateTimeFormat.HOURS_MINUTES),
            endTime: this.$moment(program.date_end),
            endTimeKey: this.$moment(program.date_end).format(DateTimeFormat.DATE_MEDIUM),
            endTimeText: this.$moment(program.date_end).format(DateTimeFormat.HOURS_MINUTES),
            key: `program-${program.id}`,
            program: program,
            markerText: this.$t('promo.schedule.Program'),
          });
        }
      });
    });

    return currentDayPrograms;
  }

  public sortScheduleItems(a: TScheduleItem, b: TScheduleItem): -1 | 0 | 1 {
    if (a.startTimeKey === b.startTimeKey) {
      if (a.endTimeKey === b.endTimeKey) {
        return 0;
      } else if (a.endTimeKey > b.endTimeKey) {
        return 1;
      } else {
        return -1;
      }
    } else if (a.startTimeKey > b.startTimeKey) {
      return 1;
    } else {
      return -1;
    }
  }

  public isStartCallButtonVisible(item: TScheduleItem): boolean {
    return item.type === ScheduleItemType.MEETING
      && !this.isScheduleItemPast(item);
  }

  public indicatorType(meeting: TMeeting): string {
    if (meeting.communication_type !== 'offline') {
      return 'online';
    } else if (meeting.communication_type === 'offline' && !this.isTimeSlotPast(meeting)) {
      return 'offline';
    } else {
      return 'expired';
    }
  }

  public isTimeSlotPast(meeting: TMeeting): boolean {
    return +meeting.date_end < ((new Date()).getTime() - (1000 * 60 * 30));
  }

}
