<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.6667 20.7568H20.8987C22.103 20.7568 23.0848 19.7881 23.0848 18.5946C23.0848 17.4011 22.1074 16.4324 20.903 16.4324C20.663 16.4324 20.4667 16.2378 20.4667 16V10.8108C20.4667 7.23027 18.1583 4.08649 14.7285 2.99243L14.5758 2.94054V2.37838C14.5758 1.06811 13.4979 0 12.1758 0C10.8536 0 9.77575 1.06811 9.77575 2.37838V2.94054L9.62303 2.98811C6.19321 4.08649 3.88484 7.23027 3.88484 10.8108V16C3.88484 16.2378 3.68848 16.4324 3.44848 16.4324C2.24412 16.4324 1.26666 17.4011 1.26666 18.5946C1.26666 19.7881 2.24848 20.7568 3.45284 20.7568H8.68484L8.70666 20.947C8.91175 22.6854 10.4041 24 12.1758 24C13.9474 24 15.4354 22.6897 15.6405 20.947L15.6667 20.7568ZM3.45284 19.8919C2.72848 19.8919 2.13939 19.3081 2.13939 18.5946C2.13939 17.8811 2.72848 17.2973 3.44848 17.2973C4.16848 17.2973 4.75757 16.7135 4.75757 16V10.8108C4.75757 7.45514 7.04412 4.52757 10.3212 3.69297L10.6485 3.61081V2.37838C10.6485 1.54378 11.3336 0.864865 12.1758 0.864865C13.0179 0.864865 13.703 1.54378 13.703 2.37838V3.60649L14.0303 3.68865C17.3074 4.52757 19.5939 7.45514 19.5939 10.8108V16C19.5939 16.7178 20.1787 17.2973 20.903 17.2973C21.6274 17.2973 22.2121 17.8811 22.2121 18.5946C22.2121 19.3081 21.623 19.8919 20.8987 19.8919H3.45284ZM14.7503 21.0119C14.5234 22.2443 13.4412 23.1351 12.1758 23.1351C10.9103 23.1351 9.82812 22.2443 9.60121 21.0119L9.55321 20.7568H14.7983L14.7503 21.0119Z" fill="black"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconNotifications = Vue.extend({
  name: 'icon-notifications',
});
export default IconNotifications;
</script>
